import { formatTime } from '../../../utility/Utils'


const initialState = {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedRegister: []
}

const FraseReducer = (state = initialState, action) => {
    let retorno
    switch (action.type) {
        case 'GET_LIST':
            retorno = {
                ...state,
                allData: action.allData,
                data: action.data,
                total: action.totalPages,
                isLoading: action.isLoading,
                params: action.params
            }
            //console.warn(`adminReducer(${action.type})->retorno: ${JSON.stringify(retorno)}`)
            return retorno
        case 'GET_BY_ID':
            action.data.dtpublicacaoinicial_time = formatTime(action.data.dtpublicacaoinicial)
            //action.data.dtpublicacaoinicial = null
            retorno = {
                ...state,
                isLoading: false,
                bcActive: 'Editar',
                data: action.data,
                selectedRegister: action.data
            }
            //console.warn(`adminReducer(${action.type})->retorno: ${JSON.stringify(retorno)}`)
            return retorno

        case 'DELETE_INVOICE':
            return { ...state }
        default:
            return { ...state }
    }
}

export default FraseReducer
