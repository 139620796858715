import { useState } from "react"
import AuthService from "../services/AuthService"

//import Moment from 'moment'
//import locale from 'moment/locale'


// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = obj => Object.keys(obj).length === 0


export const isComplexType = obj => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = html => html.replace(/<\/?[^>]+(>|$)/g, '')

// ** Checks if the passed date is today
const isToday = date => {
    const today = new Date()
    return (
        /* eslint-disable operator-linebreak */
        date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()
        /* eslint-enable */
    )
}

export const stringCapitalize = (str) => {
    const retorno = str.toString()
    return retorno.length ? retorno.charAt(0).toUpperCase() + retorno.slice(1) : retorno
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDateInFull = (value, formatting = { month: 'long', day: 'numeric', year: 'numeric' }) => {
    if (!value) return value
    return new Intl.DateTimeFormat('pt-BR', formatting).format(new Date(value))
}


//#region Formatos
const dataSimples = { month: 'numeric', day: 'numeric', year: 'numeric' }
const horaSimples = { hour: '2-digit', minute: '2-digit' }
const dataHoraSimples = { month: 'numeric', day: 'numeric', year: 'numeric', hour: '2-digit', minute: '2-digit' }

//#endregion

//#region formatDateToday()
export const formatDateToday = (value) => {
    if (!value) return value
    const now = new Date()
    const data = new Intl.DateTimeFormat('pt-BR', dataSimples).format(new Date(value))
    const hora = new Intl.DateTimeFormat('pt-BR', horaSimples).format(new Date(value))
    const hoje = new Intl.DateTimeFormat('pt-BR', dataSimples).format(now)
    const ontem = new Intl.DateTimeFormat('pt-BR', dataSimples).format(now.setDate(now.getDate() - 1))
    let retorno = new Intl.DateTimeFormat('pt-BR', dataHoraSimples).format(new Date(value))
    //console.warn(`value: ${value} - data: ${data} - hoje: ${hoje} - ontem: ${ontem} - hora: ${hora}`)
    if (data === hoje) {
        retorno = `hoje às ${hora}`
    } else if (data === ontem) {
        retorno = `ontem às ${hora}`
    } else {
        retorno = `${data} às ${hora}`
    }

    return retorno
}

//#endregion

export const formatDate = (value, formatting = { month: 'numeric', day: 'numeric', year: 'numeric' }) => {
    if (!value) return value
    return new Intl.DateTimeFormat('pt-BR', formatting).format(new Date(value))
}

export const formatToYear = (value, formatting = { year: 'numeric' }) => {
    if (!value) return value
    return new Intl.DateTimeFormat('pt-BR', formatting).format(new Date(value))
}

export const formatDateTime = (value, formatting = { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: 'numeric' }) => {
    if (!value) return value
    return new Intl.DateTimeFormat('pt-BR', formatting).format(new Date(value))
}

export const formatTime = (value, formatting = { hour: '2-digit', minute: '2-digit', second: 'numeric' }) => {
    if (!value) return value
    return new Intl.DateTimeFormat('pt-BR', formatting).format(new Date(value))
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
    try {
        const date = new Date(value)
        let formatting = { month: 'short', day: 'numeric' }

        if (toTimeForCurrentDay && isToday(date)) {
            formatting = { hour: 'numeric', minute: 'numeric' }
        }

        return new Intl.DateTimeFormat('pt-BR', formatting).format(new Date(value))
    } catch (e) {
        console.error(`Utils->formatDateToMonthShort: value: ${value} - Erro: ${e}`)
    }
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => AuthService.isAuthenticated()/*localStorage.getItem('userData')*/
export const getUserData = () => AuthService.getUser()/*JSON.parse(localStorage.getItem('userData'))*/

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
    if (userRole === 'admin') return '/'
    if (userRole === 'client') return '/access-control'
    return '/login'
}

// ** React Select Theme Colors
export const selectThemeColors = theme => ({
    ...theme,
    colors: {
        ...theme.colors,
        primary25: '#1575BB1a', // for option hover bg-color
        primary: '#1575BB', // for selected option bg-color
        neutral10: '#1575BB', // for tags bg-color
        neutral20: '#ededed', // for input border-color
        neutral30: '#ededed' // for input hover border-color
    }
})

export const isTouchDevice = () => {
    if ("ontouchstart" in window) {
        return true
    }
    return false
}

export function cutText(text, size) {
    if (!text || text.length === 0 || text.length <= size) {
        return text
    }
    const retorno = `${text.substring(0, size)}...`
    return retorno
}

//TODO implementar função de separador de milhar
export function separatorThousand(value) {
    let retorno = ''
    value = value.replace(/^/)
    if (value.length <= 3) {
        retorno = value
    } else if (value.length <= 6) {
        retorno = value
    } else if (value.length <= 9) {
        retorno = value
    } else if (value.length <= 12) {
        retorno = value
    }
    return retorno
}
