const initialState = {
    data: [],
    total: 1,
    params: {},
    allData: [],
    isLoading: true,
    selectedRegister: []
}

const AdminReducer = (state = initialState, action) => {
    let retorno
    switch (action.type) {
        case 'GET_LIST':
            retorno = {
                ...state,
                allData: action.allData,
                data: action.data,
                total: action.totalPages,
                isLoading: action.isLoading,
                params: action.params
            }
            //console.warn(`adminReducer(${action.type})->retorno: ${JSON.stringify(retorno)}`)
            return retorno
        case 'LIST_ISLOADING':
            retorno = {
                isLoading: action.isLoading
            }
            console.warn(`adminReducer(${action.type})->retorno: ${JSON.stringify(retorno)}`)
            return retorno
        case 'GET_BY_ID':
            retorno = {
                ...state,
                isLoading: false,
                bcActive: 'Editar',
                data: action.data,
                selectedRegister: action.data
            }
            //console.warn(`adminReducer(${action.type})->retorno: ${JSON.stringify(retorno)}`)
            return retorno
             
        case 'DELETE_INVOICE':
            return { ...state }
        default:
            return { ...state }
    }
}

export default AdminReducer
