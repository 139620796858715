
// ** Initial State
const initialState = {
    params: {},
    documents: [],
    currentDocument: null,
    documentsMeta: {},
    selectedDocuments: []
}

const DocFacilReducer = (state = initialState, action) => {
    //console.warn(`DocFacilReducer->action.type: ${action}`)
    switch (action.type) {
        case 'GET_DOCUMENTS':
            //console.warn(action.data)
            let currDocument = null
            if (state.currentDocument !== null && state.currentDocument !== undefined) {
                currDocument = action.data.documents.find(i => i.id === state.currentDocument.id)
            }
            return {
                ...state,
                documents: action.data.documents,
                documentsMeta: action.data.documentsMeta,
                params: action.params,
                currentDocument: currDocument
            }
        case 'PAGINATE_DOCUMENT':
            const data = action.data
            const dataIndex = state.documents.findIndex(i => i.id === data.id)
            dataIndex === 0 ? (data.hasPreviousDocument = false) : (data.hasPreviousDocument = true)
            dataIndex === state.documents.length - 1 ? (data.hasNextDocument = false) : (data.hasNextDocument = true)
            return { ...state, currentDocument: data }

        case 'UPDATE_DOCUMENTS':
            console.warn(`action.documentIds: ${action.documentIds}`)
            console.warn(action)
            function updateDocumentData(doc) {
                Object.assign(doc, action.dataToUpdate)
            }
            state.documents.forEach(doc => {
                if (action.documentIds && action.documentIds !== null && action.documentIds !== undefined) {
                    if (action.documentIds.includes(doc.id)) {
                        updateDocumentData(doc)
                    }
                }
            })
            return { ...state }

        case 'UPDATE_DOCUMENT_LABEL':
            return { ...state }
        case 'SELECT_CURRENT_DOCUMENT':
            return { ...state, currentDocument: action.document }
        case 'SELECT_DOCUMENT':
            const selectedDocuments = state.selectedDocuments
            if (!selectedDocuments.includes(action.id)) {
                selectedDocuments.push(action.id)
            } else {
                selectedDocuments.splice(selectedDocuments.indexOf(action.id), 1)
            }
            return { ...state, selectedDocuments }
        case 'SELECT_ALL_DOCUMENTS':
            const selectAllDocuments = []
            if (action.val) {
                selectAllDocuments.length = 0
                state.documents.forEach(doc => selectAllDocuments.push(doc.id))
            } else {
                selectAllDocuments.length = 0
            }
            return { ...state, selectedDocuments: selectAllDocuments }
        case 'RESET_SELECT_DOCUMENTS':
            return { ...state, selectedDocuments: [] }
        default:
            return state
    }
}

export default DocFacilReducer
